import Module from '../util/module'
import $ from '../util/query'
import smoothscroll from './smoothscroll'

class DemoModule extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this.$doughnut = $('#page-bg')
		this._render()
	}

	onContentUpdate() {
		this._render()
	}

	onScroll() {
		this._render()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_render() {
		this.$doughnut.css({
			transform: `translate3d(0px, ${smoothscroll.top * 0.3}px, 0px)`,
		})
	}
}

export default new DemoModule()
