import {gsap} from 'gsap'

class Query extends Array {
	constructor(selectors) {
		super()

		if (!selectors) {
			throw `selectors should be a string, string[], node or node[]. received: ${typeof selectors}`
		}

		const nodes = this._selectAll(selectors)

		this.push(...nodes)
	}

	// Getter/Setter
	// ----------------------------------------

	get any() {
		return this.length > 0
	}

	get one() {
		return this.first
	}

	get first() {
		return this[0]
	}

	get last() {
		return this[this.length - 1]
	}

	// Private Methods
	// ----------------------------------------

	_selectAll(selectors, root = document) {
		let nodes = []

		if (Array.isArray(selectors)) {
			selectors.forEach((s) => {
				nodes = [...nodes, ...this._select(s, root)]
			})
		} else {
			nodes = this._select(selectors, root)
		}

		return nodes
	}

	_select(selector, root = document) {
		if (typeof selector === 'string') {
			return root.querySelectorAll(selector)
		}

		if (selector instanceof Window) {
			return [window]
		}

		if (selector instanceof Document) {
			return [document]
		}

		return [selector]
	}

	// Public Methods
	// ----------------------------------------

	closest(selector) {
		let nodes = []

		this.forEach((el) => {
			const closest = el.closest(selector)

			if (closest) {
				nodes.push(closest)
			}
		})

		return new Query(nodes)
	}

	find(selectors) {
		let nodes = []

		this.forEach((el) => {
			nodes = [...nodes, ...this._selectAll(selectors, el)]
		})

		return new Query(nodes)
	}

	contains(node) {
		const index = this.findIndex((el) => el.contains(node))
		return index > -1
	}

	hasClass(classes) {
		const index = this.findIndex((el) => el.classList.contains(classes))
		return index > -1
	}

	addClass(classes) {
		this.forEach((el) => {
			el.classList.add(classes)
		})
		return this
	}

	removeClass(classes) {
		this.forEach((el) => {
			el.classList.remove(classes)
		})
		return this
	}

	toggleClass(classes) {
		this.forEach((el) => {
			el.classList.toggle(classes)
		})
		return this
	}

	css(styles) {
		this.forEach((el) => {
			Object.assign(el.style, styles)
		})
		return this
	}

	attr(attr, val = null) {
		if (val === null) {
			return this.one?.getAttribute(attr)
		}

		this.forEach((el) => {
			el.setAttribute(attr, val)
		})

		return this
	}

	removeAttr(attr) {
		this.forEach((el) => {
			el.removeAttribute(attr)
		})
		return this
	}

	data(key, val = null) {
		return this.attr(`data-${key}`, val)
	}

	style(prop, val = null) {
		if (val === null) {
			return this.one ? getComputedStyle(this.one).getPropertyValue(prop) : null
		}

		this.forEach((el) => {
			el.style.setProperty(prop, val)
		})

		return this
	}

	prop(prop, val = null) {
		if (val === null) {
			return this.one ? this.one[prop] : undefined
		}

		this.forEach((el) => {
			el[prop] = val
		})

		return this
	}

	on(e, fn, options = false) {
		let evnts = []

		if (typeof e === 'string') {
			evnts.push(e)
		}

		if (Array.isArray(e)) {
			evnts = e
		}

		this.forEach((el) => {
			evnts.forEach((evnt) => {
				if (el instanceof Window || el instanceof Document) {
					el.addEventListener(evnt, fn, options)
				} else {
					el.addEventListener(evnt, (e) => fn.call(el, e, el), options)
				}
			})
		})

		return this
	}

	off(e, fn) {
		let evnts = []

		if (typeof e === 'string') {
			evnts.push(e)
		}

		if (Array.isArray(e)) {
			evnts = e
		}

		this.forEach((el) => {
			evnts.forEach((evnt) => {
				el.removeEventListener(evnt, fn)
			})
		})

		return this
	}

	emit(name, data = null) {
		this.forEach((el) => {
			const evnt = new CustomEvent(name, {
				bubbles: true,
				detail: data,
			})

			el.dispatchEvent(evnt)
		})
		return this
	}

	text(val = null) {
		if (val === null) {
			return this.one?.innerText
		}

		this.forEach((el) => {
			el.innerText = val
		})

		return this
	}

	html(...els) {
		this.empty()
		this.append(...els)
		return this
	}

	empty() {
		this.forEach((el) => {
			while (el.firstChild) {
				el.removeChild(el.firstChild)
			}
		})
		return this
	}

	prepend(...els) {
		this.forEach((el) => {
			el.prepend(...els)
		})
		return this
	}

	append(...els) {
		this.forEach((el) => {
			el.append(...els)
		})
		return this
	}

	remove() {
		this.forEach((el) => {
			el.remove()
		})
	}

	rect() {
		return this.one?.getBoundingClientRect() || {}
	}

	async fadeIn() {
		const tl = gsap.timeline({paused: true})
		const els = [...this].filter((el) => getComputedStyle(el).getPropertyValue('display') === 'none')

		if (!els.length) return this

		tl.set(els, {
			display: 'block',
			overflow: 'hidden',
			height: 'auto',
			autoAlpha: 0,
		})

		tl.from(els, {
			duration: 0.2,
			clearProps: 'height',
			height: 0,
		})

		tl.to(els, {
			duration: 0.2,
			autoAlpha: 1,
		})

		await tl.play()
		return this
	}

	async fadeOut() {
		const tl = gsap.timeline({paused: true})
		const els = [...this].filter((el) => getComputedStyle(el).getPropertyValue('display') !== 'none')

		if (!els.length) return this

		tl.set(els, {
			overflow: 'hidden',
		})

		tl.to(els, {
			duration: 0.2,
			opacity: 0,
		})

		tl.to(els, {
			duration: 0.2,
			height: 0,
		})

		tl.add(() => {
			els.forEach((el) => {
				el.removeAttribute('style')
				el.style.display = 'none'
			})
		})

		await tl.play()
		return this
	}
}

const $ = (selectors) => new Query(selectors)

export default $
