import mixitup from 'mixitup'
import mixitupMultifilter from 'mixitup-multifilter'
import {$WINDOW} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'

mixitup.use(mixitupMultifilter)

class Filter extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		const $containers = $('[data-filterable]:not([data-init])')

		$containers.data('init', true)

		$containers.forEach((container) => {
			this._initFilters(container)
		})
	}

	onNavEnd() {
		this.setup()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_initFilters(container) {
		const $container = $(container)
		const $filters = $container.data('filters') ? $($container.data('filters')) : null
		const $clear = $container.data('clear') ? $($container.data('clear')) : null
		const params = new URLSearchParams(window.location.search)
		const config = {
			animation: {
				enable: true,
			},
			controls: {
				live: true,
				toggleLogic: 'and',
			},
			multifilter: {
				enable: true,
				logicWithinGroup: 'and',
				logicBetweenGroups: 'and',
			},
			selectors: {
				target: $container.data('target'),
			},
			callbacks: {
				onMixEnd(state) {
					if (!$container.data('msg-no-results')) return

					const $msg = $($container.data('msg-no-results'))

					if (state.hasFailed) {
						$msg.fadeIn()
					} else {
						$msg.fadeOut()
					}
				},
			},
		}

		const mixer = mixitup(container, config)

		$WINDOW.on('nav-started', () => {
			mixer.destroy()
		})

		$clear.on('click', (e) => {
			e.preventDefault()
			$filters.one?.reset()
		})

		if ($container.data('filterable') === params.get('filter')) {
			params.delete('filter')

			params.forEach((value, key) => {
				mixer.setFilterGroupSelectors(key, `.${value}`)
				mixer.parseFilterGroups()
			})
		}
	}
}

export default new Filter()
