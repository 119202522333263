import {$BODY} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'
import smoothscroll from './smoothscroll'

class ContactCTA extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this.$cta = $('#contact-cta')
		this.$footer = $('#page-footer')
		this._setVars()
		this._render()
	}

	onScroll() {
		this._render()
	}

	onResizeX() {
		this._setVars()
		this._render()
	}

	onContentUpdate() {
		this._setVars()
		this._render()
	}

	onNavEnd() {
		this._setVars()
		this._render()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_render() {
		const bodyHeight = parseInt($BODY.style('height'))
		const winBottom = smoothscroll.top + window.innerHeight
		const trigger = bodyHeight - this.footerHeight - this.ctaSize / 2

		if (trigger <= winBottom) {
			this.$cta.addClass('stuck')

			this.$cta.css({
				top: 'initial',
				bottom: `${this.footerHeight + this.ctaOffset}px`,
				transform: 'none',
			})
		} else {
			this.$cta.removeClass('stuck')

			this.$cta.css({
				top: '',
				bottom: '',
				transform: `translate3d(0px, ${smoothscroll.top}px, 0px)`,
			})
		}
	}

	_setVars() {
		this.footerHeight = parseInt(this.$footer.style('height'))
		this.ctaSize = parseInt(this.$cta.style('--contact-cta-size'))
		this.ctaOffset = parseInt(this.$cta.style('--contact-cta-offset'))
	}
}

export default new ContactCTA()
