import {$WINDOW, $DOC} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'
import smoothscroll from './smoothscroll'

class Sticky extends Module {
	setup(el) {
		this.el = el
		this.isSticky = false

		if (!this.el) {
			throw `No sticky element was found, received: ${el}`
		}

		this.parent = this.el.parentNode
		this.header = document.querySelector('#page-header')

		if (!this.parent) {
			throw `No parent was found for sticky element: ${el}`
		}

		this.el.style.willChange = 'transform'
		this.el.style.transform = 'translate3d(0, 0, 0)'
		this.parent.style.position = 'relative'

		// Bind update events
		this.scrollEv = () => this.update()
		this.resizeEv = () => this.update()
		this.resizeObserver = new ResizeObserver(() => this.update())

		$WINDOW.on('scrolled', this.scrollEv)
		$WINDOW.on('resize:ended', this.resizeEv)
		this.resizeObserver.observe(this.el)
		this.resizeObserver.observe(this.parent)

		// Update position on load
		this.update()
	}

	// Reset the element styles
	reset() {
		this.isSticky = false
		this.el.style.position = ''
		this.el.style.top = ''
		this.el.style.bottom = ''
		this.el.style.transform = ''
	}

	// Update the element styles based on the scroll position
	update() {
		const offset = this.getOffset()
		const distanceFromTop = smoothscroll.top - this.parent.offsetTop + offset
		const distanceToBottom = smoothscroll.top - (this.parent.offsetTop + this.parent.offsetHeight) + offset

		this.parent.style.minHeight = `${this.el.offsetHeight}px`

		// if the parent has not reached the top of the window
		if (distanceFromTop < 0) {
			if (this.isSticky) this.reset()
			return
		}

		// if the parent is too small for the el to stick
		if (this.parent.offsetHeight <= this.el.offsetHeight) {
			if (this.isSticky) this.reset()
			return
		}

		this.isSticky = true

		// Pin to top
		if (distanceToBottom < 0 && Math.abs(distanceToBottom) > this.el.offsetHeight) {
			this.el.style.position = 'absolute'
			this.el.style.top = 0
			this.el.style.bottom = ''
			this.el.style.transform = `translate3d(0, ${Math.abs(distanceFromTop)}px, 0)`
		}
		// Pin to bottom
		else {
			this.el.style.position = 'absolute'
			this.el.style.top = ''
			this.el.style.bottom = 0
			this.el.style.transform = 'translate3d(0, 0, 0)'
		}
	}

	// Destroy the sticky el
	destroy() {
		document.removeEventListener(EVENTS.page.scroll, this.scrollEv)
		document.removeEventListener(EVENTS.page.resizeEnd, this.resizeEv)
		this.resizeObserver.disconnect()
		this.reset()
	}

	getOffset() {
		const offset = this.el.dataset.stickyOffset
		const offsetHeader = this.el.dataset.stickyOffsetHeader || false
		let value = 0

		if (!offset) {
			return value
		}

		switch (offset) {
			case 'center':
				value = (window.innerHeight - this.el.offsetHeight) / 2
				if (offsetHeader) value += this.header.offsetHeight / 2
				break

			default:
				value = parseFloat(offset || 0)
				if (offsetHeader) value += this.header.offsetHeight
				break
		}

		return value
	}
}

// Init
// ----------------------------------------

$DOC.on(['DOMContentLoaded'], () => {
	const sticky = $('[data-sticky]')

	sticky.forEach((el) => {
		el.dataset.sticky = 'init'
		new Sticky(el)
	})
})
