import Swiper, {Navigation} from 'swiper'
import Module from '../util/module'
import $ from '../util/query'

Swiper.use([Navigation])

class Slider extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		const $sliders = $('.slider:not([data-init])')

		$sliders.data('init', true)

		$sliders.forEach((slider) => {
			this._initSlider(slider)
		})
	}

	onContentUpdate() {
		this.setup()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_initSlider(slider) {
		const gap = parseInt(slider.dataset.gap) || 0
		const center = slider.dataset.center === 'false' ? false : true
		const loop = slider.dataset.loop === 'true' ? true : false

		const swiper = new Swiper(slider, {
			loop: loop,
			slidesPerView: 'auto',
			centeredSlides: center,
			spaceBetween: gap,
			navigation: {
				nextEl: slider.dataset.next,
				prevEl: slider.dataset.prev,
			},
		})
	}
}

export default new Slider()
