import {$WINDOW, $BODY} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'

class GlobalEvents extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this._fonts()
		this._keypress()
		this._popstate()
		this._resize()
		this._unload()
		this._vh()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_fonts() {
		document.fonts.ready.then(() => {
			$WINDOW.emit('fonts-loaded')
		})
	}

	_keypress() {
		document.addEventListener('keydown', (e) => {
			$WINDOW.emit('keydown', e.key)
		})
	}

	_popstate() {
		$WINDOW.on('popstate', (e) => {
			const $overlays = $(['.modal.-active', '.drawer.-active'])

			if (!$overlays.any) {
				window.location = document.location.href
			}
		})
	}

	_resize() {
		const rect = $BODY.rect()

		this.width = rect.width
		this.height = rect.height

		new ResizeObserver(() => {
			const rect = $BODY.rect()

			if (this.width !== rect.width) {
				this.width = rect.width
				$WINDOW.emit('resized-x')
			}

			if (this.height !== rect.height) {
				this.height = rect.height
				$WINDOW.emit('resized-y')
			}
		}).observe($BODY.first)
	}

	_unload() {
		$WINDOW.on('beforeunload', () => {
			window.scrollTo(0, 0)
		})
	}

	_vh() {
		$BODY.style('--vh-initial', window.innerHeight + 'px')
		$BODY.style('--vh', window.innerHeight + 'px')

		$WINDOW.on('resize', () => {
			$BODY.style('--vh', window.innerHeight + 'px')
		})
	}
}

export default new GlobalEvents()
