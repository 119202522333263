import {$WINDOW} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'
import AnimationFrame from '../util/animation-frame'

class Cursor extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this.$cursor = $('#page-cursor')
		this.$cursorInner = this.$cursor.find('.cursor__inner')
		this.$cursorLabel = this.$cursor.find('.cursor__label')
		this.cursorRect = this.$cursor.rect()
		this.mouse = {
			x: 0,
			y: 0,
		}
		this.renderedStyles = {
			tx: {previous: 0, current: 0, amt: 0.075},
			ty: {previous: 0, current: 0, amt: 0.075},
		}

		// Init
		this._initCursor()
		this._initHoverEvents()
	}

	onLoadStart() {
		this.isLoading = true
		this.$cursor.removeClass('-enter')
		this.$cursor.addClass('-loading')
	}

	onLoadEnd() {
		this.isLoading = false
		this.$cursor.removeClass('-enter')
		this.$cursor.removeClass('-loading')
	}

	onContentUpdate() {
		this._initHoverEvents()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_initCursor() {
		$WINDOW.on('mousemove', (e) => {
			this.mouse = {
				x: e.clientX,
				y: e.clientY,
			}
		})

		this.renderedStyles.tx.previous = this.renderedStyles.tx.current = this.mouse.x - this.cursorRect.width / 2
		this.renderedStyles.ty.previous = this.renderedStyles.ty.current = this.mouse.y - this.cursorRect.height / 2

		this.anim = new AnimationFrame(() => this._render(), 60)
		this.anim.start()
	}

	_initHoverEvents() {
		$(['a', 'button', 'input:not([type="hidden"])', 'textarea', 'select']).forEach((el) => {
			if (el.dataset.cursor) return

			const $el = $(el)

			$el.on('mouseenter', () => this._enter())
			$el.on('mouseleave', () => this._leave())
			$el.data('cursor', true)
		})
	}

	_render() {
		this.renderedStyles['tx'].current = this.mouse.x - this.cursorRect.width / 2
		this.renderedStyles['ty'].current = this.mouse.y - this.cursorRect.height / 2

		for (const key in this.renderedStyles) {
			this.renderedStyles[key].previous = this._lerp(
				this.renderedStyles[key].previous,
				this.renderedStyles[key].current,
				this.renderedStyles[key].amt,
			)
		}

		this.$cursor.style('--cursor-x', `${this.renderedStyles['tx'].previous}px`)
		this.$cursor.style('--cursor-y', `${this.renderedStyles['ty'].previous}px`)

		// this.$cursor.css({
		// 	transform: `translate3d(${this.renderedStyles['tx'].previous}px, ${this.renderedStyles['ty'].previous}px, 0px)`,
		// })
	}

	_enter() {
		this.$cursor.addClass('-enter')
	}

	_leave() {
		this.$cursor.removeClass('-enter')
	}

	_lerp(a, b, n) {
		return (1 - n) * a + n * b
	}
}

export default new Cursor()

// ;(() => {

// 	// Cursor
// 	// ----------------------------------------

// 	// Init
// 	// ----------------------------------------

// 	let cursor

// 	function initHoverEvents() {
// 		if (!DEVICE.mouse || !DEVICE.md) return

// 		selectAll('a, button, input:not([type="hidden"]), textarea, select').forEach((el) => {
// 			if (el.dataset.cursor) return

// 			listen('mouseenter', el, () => cursor.enter())
// 			listen('mouseleave', el, () => cursor.leave())
// 			el.setAttribute('data-cursor', true)
// 		})
// 	}

// 	// Events
// 	// ----------------------------------------

// 	listen(EVENTS.page.ready, document, () => {
// 		// if (!DEVICE.mouse || !DEVICE.md) return

// 		const el = select('#page-cursor')

// 		cursor = new Cursor(el)

// 		initHoverEvents()

// 		listen(EVENTS.page.loading, document, () => cursor.loading())
// 		listen(EVENTS.page.loaded, document, () => cursor.loaded())
// 	})

// 	listen(EVENTS.page.navEnd, document, () => {
// 		initHoverEvents()
// 	})

// 	listen(EVENTS.page.change, document, () => {
// 		initHoverEvents()
// 	})
// })()
