import $ from './query'

export const $WINDOW = $(window)
export const $DOC = $(document)
export const $HTML = $(document.documentElement)
export const $BODY = $(document.body)

export const $PAGE = $('#page')
export const $PAGE_WRAPPER = $('#page-wrapper')
export const $PAGE_SCROLLER = $('#page-scroller')
export const $PAGE_CONTENT = $('#page-content')
export const $PAGE_OVERLAYS = $('#page-overlays')

export const isTouchEnabled = () => matchMedia('(pointer:coarse)').matches && !matchMedia('(hover)').matches
export const isStylusEnabled = () => matchMedia('(pointer:fine)').matches && !matchMedia('(hover)').matches
export const isMouseEnabled = () => matchMedia('(pointer:fine)').matches && matchMedia('(hover)').matches
export const isSM = () => matchMedia('(min-width: 576px)').matches
export const isMD = () => matchMedia('(min-width: 768px)').matches
export const isLG = () => matchMedia('(min-width: 992px)').matches
export const isXL = () => matchMedia('(min-width: 1200px)').matches

export const stringToHTML = (str) => document.createRange().createContextualFragment(str)
