import axios from 'redaxios'
import {$WINDOW, $PAGE, $PAGE_OVERLAYS} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'

class ResourcePage extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		if (!$PAGE.hasClass('resources-page') && !$PAGE.hasClass('resource-page')) return

		this.isLoading = false

		this._initCards()

		this._checkWindowState()
	}

	onContentUpdate() {
		this.setup()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_initCards() {
		const $cards = $('.resource-card > a:not([data-init])')

		$cards.data('init', true)

		$cards.on('click', (e, card) => {
			e.preventDefault()
			this._getResource(card.dataset.id, card.dataset.slug)
		})
	}

	_checkWindowState() {
		const slug = window.location.pathname.slice(1).split('/').pop()
		const hash = window.location.hash.slice(1)
		const $card = $([`.resource-card[data-slug="${slug}"] > a`, `.resource-card[data-hash="${hash}"] > a`])

		if ($card.any) {
			this._getResource($card.data('id'), $card.data('slug'))
		}
	}

	async _getResource(id, slug) {
		if (!id || !slug || this.isLoading) return

		this.isLoading = true

		let $modal = $(`.modal#modal-${id}`)

		if (!$modal.any) {
			$WINDOW.emit('loading')

			const {data} = await axios({
				method: 'get',
				url: `${window.location.origin}/resources/${slug}`,
			})

			const parser = new DOMParser()
			const newDoc = parser.parseFromString(data, 'text/html')
			const newOverlays = newDoc.querySelector('#page-overlays')

			$PAGE_OVERLAYS.append(...newOverlays.childNodes)
			$WINDOW.emit('content-updated')
			$WINDOW.emit('loaded')

			$modal = $(`.modal#modal-${id}`)
		}

		$modal.emit('modal:open')

		this.isLoading = false
	}
}

export default new ResourcePage()
