import {gsap} from 'gsap'
import {$WINDOW, $BODY} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'
import smoothscroll from './smoothscroll'

class Drawer extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this.isAnimating = false
		this._initDrawers()
		this._initToggles()
		this._checkWindowState()

		$WINDOW.on('popstate', (e) => {
			if (this.$activeDrawer.any) {
				e.preventDefault()
				this._closeActiveDrawer()
			}
		})

		$BODY.on('click', (e) => {
			const $target = $(e.target)

			if (!$target.closest('.drawer').any) {
				this._closeActiveDrawer()
			}
		})
	}

	onKeydown(e) {
		if (e.detail === 'Escape') {
			this._closeActiveDrawer()
		}
	}

	onNavStart() {
		this._closeActiveDrawer()
	}

	onContentUpdate() {
		this._initDrawers()
		this._initToggles()
		this._checkWindowState()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_initToggles() {
		const $toggles = $('[data-drawer]:not([data-init])')

		$toggles.data('init', true)

		$toggles.on('click', (e, el) => {
			e.preventDefault()

			const $toggle = $(el)
			const $drawer = $(`.drawer${$toggle.data('drawer')}`)

			if (!$drawer.any) return

			this._toggleDrawer($drawer)
		})
	}

	_initDrawers() {
		const $drawers = $('.drawer:not([data-init])')

		$drawers.data('init', true)

		$drawers.on('drawer:open', (e, el) => {
			const $drawer = $(el)
			this._openDrawer($drawer)
		})

		$drawers.on('drawer:close', (e, el) => {
			const $drawer = $(el)
			this._closeDrawer($drawer)
		})
	}

	_checkWindowState() {
		const slug = window.location.pathname.slice(1).split('/').pop()
		const hash = window.location.hash.slice(1)
		const $drawer = $([`.drawer[data-slug="${slug}"]`, `.drawer[data-hash="${hash}"]`])

		if ($drawer.any) {
			this._openDrawer($drawer)
		}
	}

	async _toggleDrawer($drawer) {
		const $active = this.$activeDrawer

		if ($active.any && $active.attr('id') !== $drawer.attr('id')) {
			await this._closeDrawer($active)
		}

		if ($drawer.hasClass('-active')) {
			await this._closeDrawer($drawer)
		} else {
			await this._openDrawer($drawer)
		}
	}

	async _openDrawer($drawer) {
		if (this.isAnimating) return

		this.isAnimating = true

		$drawer.emit('drawer:will-open')

		smoothscroll.disableScroll()

		await gsap.fromTo(
			$drawer,
			{
				display: 'none',
				xPercent: 100,
			},
			{
				duration: 0.5,
				display: 'block',
				xPercent: 0,
			},
		)

		$drawer.emit('drawer:did-open')
		$drawer.addClass('-active')

		this._updateLocation($drawer)
		this._updateIframe($drawer)
		this.isAnimating = false
	}

	async _closeDrawer($drawer) {
		if (this.isAnimating) return

		this.isAnimating = true

		$drawer.emit('drawer:will-close')

		await gsap.fromTo(
			$drawer,
			{
				display: 'block',
				xPercent: 0,
			},
			{
				duration: 0.5,
				display: 'none',
				xPercent: 100,
			},
		)

		smoothscroll.enableScroll()

		$drawer.emit('drawer:did-close')
		$drawer.removeClass('-active')

		this._updateLocation($drawer)
		this._updateIframe($drawer)
		this.isAnimating = false
	}

	async _closeActiveDrawer() {
		const $active = this.$activeDrawer

		if ($active.any) {
			await this._closeDrawer($active)
		}
	}

	_updateLocation($drawer) {
		const slug = $drawer.data('slug') || null
		const hash = $drawer.data('hash') || null
		const isActive = $drawer.hasClass('-active')

		if (slug) {
			let url = window.location.origin
			const path = window.location.pathname.split('/')

			url += path.filter((s) => s !== slug).join('/')

			if (isActive) {
				url += `/${slug}`
			}

			if (url !== window.location.href) {
				history.pushState(null, null, url)
			}

			return
		}

		if (hash) {
			if (isActive) {
				history.pushState(null, null, `#${hash}`)
			} else {
				history.pushState(null, null, `${window.location.pathname}`)
			}

			return
		}
	}

	_updateIframe($drawer) {
		const isActive = $drawer.hasClass('-active')
		const $iframe = $drawer.find('iframe[data-src]')

		if (isActive) {
			$iframe.forEach((iframe) => {
				iframe.setAttribute('src', iframe.dataset.src)
			})
		} else {
			$iframe.removeAttr('src')
		}
	}

	// ----------------------------------------
	// Getter/Setter
	// ----------------------------------------

	get $activeDrawer() {
		return $('.drawer.-active')
	}
}

export default new Drawer()
