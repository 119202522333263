import {$WINDOW} from './global'

class Module {
	constructor(...params) {
		$WINDOW.on('DOMContentLoaded', (e) => {
			if (this.setup) {
				this.setup(...params)
			}
		})

		if (this.onNavStart) {
			$WINDOW.on('nav-started', (e) => this.onNavStart(e))
		}

		if (this.onNavEnd) {
			$WINDOW.on('nav-ended', (e) => this.onNavEnd(e))
		}

		if (this.onLoadStart) {
			$WINDOW.on('loading', (e) => this.onLoadStart(e))
		}

		if (this.onLoadEnd) {
			$WINDOW.on('loaded', (e) => this.onLoadEnd(e))
		}

		if (this.onContentUpdate) {
			$WINDOW.on('content-updated', (e) => this.onContentUpdate(e))
		}

		if (this.onResize) {
			$WINDOW.on('resized', (e) => this.onResize(e))
		}

		if (this.onResizeX) {
			$WINDOW.on('resized-x', (e) => this.onResizeX(e))
		}

		if (this.onResizeY) {
			$WINDOW.on('resized-y', (e) => this.onResizeY(e))
		}

		if (this.onFontLoad) {
			$WINDOW.on('fonts-loaded', (e) => this.onFontLoad(e))
		}

		if (this.onParamUpdate) {
			$WINDOW.on('params-updated', (e) => this.onParamUpdate(e))
		}

		if (this.onHashUpdate) {
			$WINDOW.on('hash-updated', (e) => this.onHashUpdate(e))
		}

		if (this.onScroll) {
			$WINDOW.on('scrolled', (e) => this.onScroll(e))
		}

		if (this.onKeydown) {
			$WINDOW.on('keydown', (e) => this.onKeydown(e))
		}
	}
}

export default Module
