import {gsap} from 'gsap'
import {$WINDOW, $BODY, isMD} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'
import smoothscroll from './smoothscroll'

class Modal extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this.isAnimating = false
		this._initModals()
		this._initToggles()
		this._checkWindowState()

		$WINDOW.on('popstate', (e) => {
			if (this.$activeModal.any) {
				e.preventDefault()
				this._closeActiveModal()
			}
		})

		$BODY.on('click', (e) => {
			const $target = $(e.target)

			if ($target.hasClass('modal')) {
				this._closeActiveModal()
			}
		})
	}

	onKeydown(e) {
		if (e.detail === 'Escape') {
			this._closeActiveModal()
		}
	}

	onNavStart() {
		this._closeActiveModal()
	}

	onContentUpdate() {
		this._initModals()
		this._initToggles()
		this._checkWindowState()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_initToggles() {
		const $toggles = $('[data-modal]:not([data-init])')

		$toggles.data('init', true)

		$toggles.on('click', (e, el) => {
			e.preventDefault()

			const $toggle = $(el)
			const $modal = $(`.modal${$toggle.data('modal')}`)

			if (!$modal.any) return

			this._toggleModal($modal)
		})
	}

	_initModals() {
		const $modals = $('.modal:not([data-init])')

		$modals.data('init', true)

		$modals.on('modal:open', (e, el) => {
			const $modal = $(el)
			this._openModal($modal)
		})

		$modals.on('modal:close', (e, el) => {
			const $modal = $(el)
			this._closeModal($modal)
		})
	}

	_checkWindowState() {
		const slug = window.location.pathname.slice(1).split('/').pop()
		const hash = window.location.hash.slice(1)
		const $modal = $([`.modal[data-slug="${slug}"]`, `.modal[data-hash="${hash}"]`])

		if ($modal.any) {
			const $first = $($modal.one)
			this._openModal($first)
		}
	}

	async _toggleModal($modal) {
		const $active = this.$activeModal

		if ($active.any && $active.attr('id') !== $modal.attr('id')) {
			await this._closeModal($active)
		}

		if ($modal.hasClass('-active')) {
			await this._closeModal($modal)
		} else {
			await this._openModal($modal)
		}
	}

	async _openModal($modal) {
		if (this.isAnimating) return

		this.isAnimating = true

		$modal.emit('modal:will-open')

		smoothscroll.disableScroll()

		const tl = this._getTimeline($modal, false)
		await tl.play()

		$modal.emit('modal:did-open')
		$modal.addClass('-active')

		this._updateLocation($modal)
		this._updateIframe($modal)
		this.isAnimating = false
	}

	async _closeModal($modal) {
		if (this.isAnimating) return

		this.isAnimating = true

		$modal.emit('modal:will-close')

		const tl = this._getTimeline($modal, true)
		await tl.reverse(0)

		smoothscroll.enableScroll()

		$modal.emit('modal:did-close')
		$modal.removeClass('-active')

		this._updateLocation($modal)
		this._updateIframe($modal)
		this.isAnimating = false
	}

	async _closeActiveModal() {
		const $active = this.$activeModal

		if ($active.any) {
			await this._closeModal($active)
		}
	}

	_updateLocation($modal) {
		const slug = $modal.data('slug') || null
		const hash = $modal.data('hash') || null
		const isActive = $modal.hasClass('-active')

		if (slug) {
			let url = window.location.origin
			const path = window.location.pathname.split('/')

			url += path.filter((s) => s !== slug).join('/')

			if (isActive) {
				url += `/${slug}`
			}

			if (url !== window.location.href) {
				history.pushState(null, null, url)
			}

			return
		}

		if (hash) {
			if (isActive) {
				history.pushState(null, null, `#${hash}`)
			} else {
				history.pushState(null, null, `${window.location.pathname}`)
			}

			return
		}
	}

	_updateIframe($modal) {
		const isActive = $modal.hasClass('-active')
		const $iframe = $modal.find('iframe[data-src]')

		if (isActive) {
			$iframe.forEach((iframe) => {
				iframe.setAttribute('src', iframe.dataset.src)
			})
		} else {
			$iframe.removeAttr('src')
		}
	}

	_getTimeline($modal, reversed = false) {
		const $content = $modal.find('.modal__main')
		const $sidebar = $modal.find('.modal__aside')
		const tl = gsap.timeline({paused: true})

		tl.fromTo(
			$modal,
			{
				display: 'none',
				autoAlpha: 0,
			},
			{
				duration: 0.25,
				display: 'block',
				autoAlpha: 1,
			},
			0,
		)

		if (!reversed && $sidebar.length) {
			const delay = isMD() ? 0.15 : 0

			tl.fromTo(
				$sidebar,
				{
					y: 250,
					autoAlpha: 0,
				},
				{
					duration: 1.5,
					ease: 'expo.out',
					clearProps: true,
					autoAlpha: 1,
					y: 0,
				},
				delay,
			)
		}

		if (!reversed && $content.length) {
			const delay = isMD() ? 0.2 : 0

			tl.fromTo(
				$content,
				{
					y: 250,
					autoAlpha: 0,
				},
				{
					duration: 1.5,
					ease: 'expo.out',
					clearProps: true,
					autoAlpha: 1,
					y: 0,
				},
				delay,
			)
		}

		return tl
	}

	// ----------------------------------------
	// Getter/Setter
	// ----------------------------------------

	get $activeModal() {
		return $('.modal.-active')
	}
}

export default new Modal()
