import Module from '../util/module'
import $ from '../util/query'

class FromUpload extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		const $inputs = $('.form-upload:not([data-init])')

		$inputs.data('init', true)
		$inputs.on('change', (e, el) => this._handleUpload(e, el))
		$inputs.closest('form').on('reset', (e, form) => this._handleReset(form))
	}

	onContentUpdate() {
		this.setup()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_handleUpload(e, el) {
		const file = e.target.files[0]
		const $input = $(el)
		const $inputName = $input.find('.form-upload__name')

		$inputName.text(file.name)
	}

	_handleReset(form) {
		$(form).find('.form-upload__name').text('')
	}
}

export default new FromUpload()
