import {$WINDOW, isMouseEnabled} from './global'
import $ from './query'
import AnimationFrame from './animation-frame'
import smoothscroll from '../modules/smoothscroll'

class Orb {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	constructor(orb) {
		this.isEnabled = false
		this.$orb = $(orb)

		this.mouse = {
			x: 0,
			y: 0,
		}

		this.renderedStyles = {
			tx: {previous: 0, current: 0, amt: 0.225},
			ty: {previous: 0, current: 0, amt: 0.225},
		}

		$WINDOW.on('mousemove', (e) => {
			if (isMouseEnabled()) {
				this.mouse = {
					x: e.clientX,
					y: e.clientY,
				}
			}
		})

		$WINDOW.on('resize', (e) => {
			if (isMouseEnabled()) {
				this.isEnabled = true
				this.$orb.addClass('-enabled')
			} else {
				this.isEnabled = false
				this.$orb.removeClass('-enabled')
				this.$orb.removeAttr('style')
			}
		})

		if (isMouseEnabled()) {
			this.isEnabled = true
			this.$orb.addClass('-enabled')
		}

		this.renderedStyles.tx.previous = this.renderedStyles.tx.current = this.mouse.x - this.$orb.one.offsetWidth / 2
		this.renderedStyles.ty.previous = this.renderedStyles.ty.current = this.mouse.y - this.$orb.one.offsetHeight / 2
		this.anim = new AnimationFrame(() => this._render(), 60)
		this.anim.start()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_render() {
		if (!this.isEnabled) return

		this.renderedStyles['tx'].current = this.mouse.x - this.$orb.one.offsetWidth / 2
		this.renderedStyles['ty'].current = this.mouse.y + smoothscroll.top - this.$orb.one.offsetHeight / 2

		for (const key in this.renderedStyles) {
			this.renderedStyles[key].previous = this._lerp(
				this.renderedStyles[key].previous,
				this.renderedStyles[key].current,
				this.renderedStyles[key].amt,
			)
		}

		this.$orb.css({
			top: 0,
			left: 0,
			transform: `translate3d(${this.renderedStyles['tx'].previous}px, ${this.renderedStyles['ty'].previous}px, 0px)`,
		})
	}

	_lerp(a, b, n) {
		return (1 - n) * a + n * b
	}
}

export default Orb
