import {gsap} from 'gsap'
import axios from 'redaxios'
import {$WINDOW, $BODY, $PAGE_CONTENT, $PAGE_OVERLAYS} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'
import smoothscroll from '../modules/smoothscroll'
// import preloader from '../modules/preloader'

class Router extends Module {
	setup() {
		this.loading = false

		$BODY.on('click', async (e) => {
			if (!e.target) return false

			const target = e.target
			const anchor = target.closest('a')

			if (!anchor) return false

			const href = anchor.getAttribute('href')
			const path = href.split(/[?#]+/)[0]
			const params = href.split(/[?#]+/)[1] || null
			const host = anchor.host

			const currentHref = window.location.href
			const currentPath = currentHref.split(/[?#]+/)[0]
			const currentParams = currentHref.split(/[?#]+/)[1] || null
			const currentHost = window.location.host

			if (href === '#') {
				// console.log('Link href matches #')
				e.preventDefault()
				return false
			}

			if (anchor.dataset.ignore || anchor.dataset.toggle) {
				// console.log('Link contains a forbidden data attribute')
				return false
			}

			if (anchor.getAttribute('role') === 'button') {
				// console.log('Link has a forbidden role')
				return false
			}

			if (currentHost !== host) {
				// console.log('Link is an external link')
				return false
			}

			if (currentPath === path) {
				// console.log('Link is to current page')
				e.preventDefault()

				if (params && params !== currentParams) {
					window.history.pushState('', '', href)

					if (href.indexOf('#') > 0) $WINDOW.emit('hash-updated', new URLSearchParams(params))
					if (href.indexOf('?') > 0) $WINDOW.emit('params-updated', new URLSearchParams(params))
				}

				return false
			}

			if (href.indexOf('tel:') >= 0 || href.indexOf('mailto:') >= 0) {
				// console.log('Link is an email or tel link')
				return false
			}

			e.preventDefault()

			this.to(href)
		})
	}

	onContentUpdate() {
		this.emailDecode()
	}

	async to(href) {
		if (this.loading) return

		this.loading = true
		$WINDOW.emit('nav-started')
		$WINDOW.emit('loading')
		$BODY.addClass('loading')

		this.updateActiveState(href)

		if (typeof window.history.pushState === 'function') {
			window.history.pushState('', '', href)
		}

		smoothscroll.enableScroll()
		smoothscroll.scrollToTop()

		const [data] = await Promise.all([this.fetchData(href), this.animateOut()])
		await this.render(data, href)
		await this.animateIn()

		this.loading = false
		$WINDOW.emit('nav-ended')
		$WINDOW.emit('loaded')
		$BODY.removeClass('loading')
	}

	async animateOut() {
		const tl = gsap.timeline({paused: true})

		tl.to(
			$PAGE_CONTENT,
			{
				duration: 0.75,
				opacity: 0,
			},
			0,
		)

		await tl.play()
	}

	async animateIn() {
		const tl = gsap.timeline({paused: true})

		tl.to(
			$PAGE_CONTENT,
			{
				duration: 0.75,
				opacity: 1,
			},
			0,
		)

		await tl.play()
	}

	async fetchData(href) {
		const {data} = await axios.get({
			method: 'get',
			url: href,
			validateStatus: (status) => {
				return status === 200 || status === 404
			},
		})

		return data
	}

	async render(data, href) {
		const parser = new DOMParser()
		const newDoc = parser.parseFromString(data, 'text/html')
		const newBody = newDoc.querySelector('body')
		const newContent = newDoc.querySelector('#page-content')
		const newOverlays = newDoc.querySelector('#page-overlays')
		const title = newDoc.querySelector('title').textContent

		// Replace the old content with the new content
		document.title = title

		$PAGE_CONTENT.html(...newContent.childNodes)
		$PAGE_OVERLAYS.html(...newOverlays.childNodes)
		$BODY.attr('class', newBody.getAttribute('class'))

		// Trigger gtag
		if (gtag && typeof gtag === 'function') {
			gtag('config', 'G-DTYKYT536M', {
				page_title: title,
				page_path: href.split('.co.nz').pop(),
			})
		}

		$WINDOW.emit('content-updated')
	}

	updateActiveState(href) {
		const $links = $('.nav__link')

		$links.forEach((link) => {
			if (link.getAttribute('href') === href) {
				link.classList.add('-active')
			} else {
				link.classList.remove('-active')
			}
		})
	}

	emailDecode() {
		document.querySelectorAll('a[href*="email-protection"]').forEach(function (self) {
			let href = self.getAttribute('href');
			let string = href.split('#')[1];
			let decoded_email;

			if (!string) string = self.getAttribute('data-cfemail');

			decoded_email = this.cfDecodeEmail(string);

			if (!!self.getAttribute('data-cfemail')) self.innerText = decoded_email;

			self.setAttribute('href', 'mailto:' + decoded_email);
			self.setAttribute('target', '_blank');
			self.setAttribute('data-ignore', 'true');
			self.setAttribute('rel', 'noopener noreferrer');
		});

		document.querySelectorAll('span.__cf_email__').forEach(function (self) {
			let string = self.getAttribute('data-cfemail');
			let decoded_email;

			decoded_email = this.cfDecodeEmail(string);
			self.innerText = decoded_email;
		});
	}

	cfDecodeEmail(encodedString) {
		var email = "", r = parseInt(encodedString.substr(0, 2), 16), n, i;
		for (n = 2; encodedString.length - n; n += 2){
			i = parseInt(encodedString.substr(n, 2), 16) ^ r;
			email += String.fromCharCode(i);
		}
		return email;
	}
}

export default new Router()
