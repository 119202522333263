import Module from '../util/module'
import $ from '../util/query'

class Lazy extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		const options = {
			root: null,
			rootMargin: '0px',
		}

		this.observer = new IntersectionObserver(this._handleIntersect, options)
		this._initLazyImgs()
	}

	onNavStart() {
		this.observer.disconnect()
	}

	onContentUpdate() {
		this._initLazyImgs()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_initLazyImgs() {
		const $items = $('.lazy:not([data-init])')

		$items.data('init', true)

		$items.forEach((item) => {
			this.observer.observe(item)
		})
	}

	_handleIntersect(entries, observer) {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) return

			const $el = $(entry.target)
			const $img = $el.find('img.lazy__img')
			const $sources = $el.find('[data-srcset]')

			$img.on('load', () => $el.addClass('-loaded'))

			$sources.forEach((s) => {
				s.setAttribute('srcset', s.dataset.srcset)
				s.removeAttribute('data-srcset')
			})

			observer.unobserve(entry.target)
		})
	}
}

export default new Lazy()
