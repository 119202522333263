import {gsap} from 'gsap'
import {SplitText} from 'gsap/SplitText'
import {MorphSVGPlugin} from 'gsap/MorphSVGPlugin'
import Module from '../util/module'
import Orb from '../util/orb'
import $ from '../util/query'
import smoothscroll from '../modules/smoothscroll'

gsap.registerPlugin(MorphSVGPlugin, SplitText)

class Preloader extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	async setup() {
		const $preloader = $('#page-preloader')

		if (!$preloader.any) return

		const $content = $preloader.find('.preloader__content')
		const $links = $('.home-landing__pathway-link')

		await smoothscroll.scrollToTop()
		smoothscroll.disableScroll()

		new Orb('#page-preloader .orb')

		const tl = gsap.timeline({paused: true})
		const textIn = this._tlTextIn()
		const wipeIn = this._tlWipeIn()
		const wipeOut = this._tlWipeOut()

		tl.add(textIn)
		tl.add(wipeIn)

		if ($content.any) {
			tl.add(() => $content.remove())
		}

		if ($links.any) {
			tl.set($links, {
				opacity: 0,
				y: '100%',
			})
		}

		tl.add(wipeOut)

		if ($links.any) {
			tl.to(
				$links,
				{
					stagger: 0.1,
					clearProps: true,
					opacity: 1,
					y: '0%',
				},
				'=-0.5',
			)
		}

		await tl.play()

		smoothscroll.enableScroll()
	}

	// ----------------------------------------
	// Public methods
	// ----------------------------------------

	async wipeIn() {
		const tl = this._tlWipeIn()
		await tl.play(0)
	}

	async wipeOut() {
		const tl = this._tlWipeOut()
		await tl.play(0)
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_tlTextIn() {
		const tl = gsap.timeline()
		const $line1 = $('#line-1')
		const $line2 = $('#line-2')
		const $highlight = $line2.find('span')
		const txt = new SplitText([...$line1, ...$line2], {
			type: 'words, chars',
			wordsClass: 'word',
			charsClass: 'char',
			reduceWhiteSpace: true,
		})

		$line1.css({opacity: 1})
		$line2.css({opacity: 1})

		tl.to(txt.words, {
			duration: 1,
			stagger: 0.1,
			ease: 'circ.out',
			opacity: 1,
			y: 0,
		})

		tl.to($highlight, {
			duration: 1,
			ease: 'linear',
			color: '#ffffff',
		})

		return tl
	}

	_tlWipeIn() {
		const tl = gsap.timeline()

		tl.set('#page-preloader', {display: 'block'})

		tl.set('#b1', {visibility: 'visible'})

		tl.set('#b2', {visibility: 'hidden'})

		tl.to('#blob-1', {
			duration: 1.5,
			ease: 'power4.inOut',
			keyframes: [
				{morphSVG: '#blob-2', duration: 1},
				{morphSVG: '#blob-3', duration: 1},
				{morphSVG: '#blob-4', duration: 1},
			],
		})

		return tl
	}

	_tlWipeOut() {
		const tl = gsap.timeline()

		tl.set('#b1', {visibility: 'hidden'})

		tl.set('#b2', {visibility: 'visible'})

		tl.to('#blob-5', {
			duration: 1.5,
			ease: 'power4.inOut',
			keyframes: [
				{morphSVG: '#blob-6', duration: 1},
				{morphSVG: '#blob-7', duration: 1},
				{morphSVG: '#blob-8', duration: 1},
			],
		})

		tl.set('#page-preloader', {display: 'none'})

		return tl
	}
}

export default new Preloader()
