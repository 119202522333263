import {$PAGE, isMouseEnabled} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'
import Orb from '../util/orb'
import smoothscroll from '../modules/smoothscroll'

class HomePage extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		if (!$PAGE.hasClass('home-page')) return

		this.$orb = $('.home-landing__orb')

		new Orb('.home-landing__orb')
	}

	onScroll() {
		if (!$PAGE.hasClass('home-page')) return

		if (isMouseEnabled()) {
			this.$orb.css({
				top: 'initial',
			})
		} else {
			this.$orb.css({
				top: `calc((var(--vh, 100vh) / 2) + ${smoothscroll.top * 1}px`,
			})
		}
	}

	onContentUpdate() {
		this.setup()
	}
}

export default new HomePage()
