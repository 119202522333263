import {gsap} from 'gsap'
import Module from '../util/module'
import $ from '../util/query'

class Switcher extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		const $switchers = $('.switcher:not([data-init])')

		$switchers.data('init', true)

		$switchers.forEach((el) => {
			const $switcher = $(el)
			const $buttons = $switcher.find('.switcher-nav__btn')

			$buttons.on('click', async (e, btn) => {
				e.preventDefault()

				this._updateNav($switcher)
				this._updateContent($switcher)

				$switcher.toggleClass('-active')
			})
		})
	}

	onContentUpdate() {
		this.setup()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	async _updateNav($switcher) {
		const isActive = $switcher.hasClass('-active')
		const tl = this._getNavTimeline($switcher)

		tl.timeScale(1)

		if (isActive) {
			await tl.reverse(0)
		} else {
			await tl.play()
		}
	}

	_getNavTimeline($switcher) {
		const $indicator = $switcher.find('.switcher-nav__indicator')
		const rect = $indicator.rect()
		const tl = gsap.timeline({
			paused: true,
		})

		tl.fromTo(
			$indicator,
			{
				left: 4,
				xPercent: 0,
			},
			{
				duration: 1,
				ease: 'expo.inOut',
				left: 'calc(100% - 4px)',
				xPercent: -100,
			},
			0,
		)

		tl.fromTo(
			$indicator,
			{
				width: rect.width,
			},
			{
				duration: 0.5,
				ease: 'expo.in',
				width: rect.width * 2,
			},
			0,
		)

		tl.fromTo(
			$indicator,
			{
				duration: 0.5,
				width: rect.width * 2,
			},
			{
				duration: 0.5,
				ease: 'expo.out',
				clearProps: 'width',
				width: rect.width,
			},
			0.5,
		)

		return tl
	}

	async _updateContent($switcher) {
		const tl = this._getContentTimeline($switcher)

		tl.timeScale(1)

		await tl.play()
	}

	_getContentTimeline($switcher) {
		const $activeContent = $switcher.find('.switcher-content__group.-active')
		const $inactiveContent = $switcher.find('.switcher-content__group:not(.-active)')

		const tl = gsap.timeline({
			paused: true,
			onComplete() {
				$activeContent.removeClass('-active')
				$inactiveContent.addClass('-active')
			},
			onReverseComplete() {
				$activeContent.removeClass('-active')
				$inactiveContent.addClass('-active')
			},
		})

		// Hide active content
		tl.fromTo(
			$activeContent,
			{
				autoAlpha: 1,
				x: 0,
			},
			{
				duration: 0.5,
				autoAlpha: 0,
				x: 20,
			},
			0,
		)

		// Show inactive content
		tl.fromTo(
			$inactiveContent,
			{
				autoAlpha: 0,
				x: 20,
			},
			{
				duration: 0.5,
				autoAlpha: 1,
				x: 0,
			},
			0.5,
		)

		return tl
	}
}

export default new Switcher()
