import {gsap} from 'gsap'
import Module from '../util/module'
import $ from '../util/query'
import smoothscroll from './smoothscroll'

class HeaderModule extends Module {
	setup() {
		this.$contact = $('#drawer-contact')
		this.$header = $('#page-header')
		this.$menu = $('#mobile-menu')
		this.$menuOpen = $('#menu-open')
		this.$menuClose = $('#menu-close')
		this.isMenuOpen = false

		this.$menuOpen.on('click', () => this._openMenu())
		this.$menuClose.on('click', () => this._closeMenu())
		this.$contact.on('drawer:will-open', () => this._closeMenu())
	}

	onNavStart() {
		if (this.isMenuOpen) {
			this._closeMenu()
		}
	}

	async _openMenu() {
		if (this.isMenuOpen) return

		this.isMenuOpen = true

		smoothscroll.disableScroll()

		await gsap.fromTo(
			this.$menu,
			{
				display: 'none',
				autoAlpha: 0,
			},
			{
				display: 'grid',
				autoAlpha: 1,
			},
		)
	}

	async _closeMenu() {
		if (!this.isMenuOpen) return

		this.isMenuOpen = false

		await gsap.fromTo(
			this.$menu,
			{
				display: 'grid',
				autoAlpha: 1,
			},
			{
				display: 'none',
				autoAlpha: 0,
				clearProps: true,
			},
		)

		smoothscroll.enableScroll()
	}
}

export default new HeaderModule()
